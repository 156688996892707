import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { HubtypeUser } from '../../models/hubtype-user'
import * as fromRoot from '../../reducers'

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit, OnDestroy {
  public error: string
  public ssoUrl: string = environment.ssoUrl
  private ngUnsubscribe: Subject<void> = new Subject<void>()
  private returnUrl
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.error = params['error']
    })
    this.returnUrl = this.route.snapshot.queryParams['returnUrl']
    this.store
      .select(fromRoot.getMeState)
      .pipe(filter(Boolean), takeUntil(this.ngUnsubscribe))
      .subscribe((me: HubtypeUser) => {
        if (me && !me.must_change_password) {
          if (this.returnUrl) {
            this.zone.run(() => this.router.navigateByUrl(this.returnUrl))
          } else {
            const url = `/${me.preferred_product || 'desk'}`
            this.zone.run(() => this.router.navigate([url]))
          }
        } else {
          this.zone.run(() => this.router.navigate(['/auth/reset-password']))
        }
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
