import produce, { Draft } from 'immer'
import { AnalyticsAction, AnalyticsActionType } from '../actions/analytics'
import { AnalyticsState, analyticsInitialState } from './analytics.state'

export function reducer(state: AnalyticsState, action: AnalyticsAction) {
  return produce(
    (draftState: Draft<AnalyticsState>, appraisalAction: AnalyticsAction) => {
      switch (appraisalAction.type) {
        case AnalyticsActionType.SET_DATE_FROM:
          draftState.dateFrom = appraisalAction.payload
          break

        default:
          return draftState
      }
    },
    analyticsInitialState
  )(state, action)
}
