import { CommonModule } from '@angular/common'
import { Component, Inject, type OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { Store } from '@ngrx/store'
import { AngularComponent } from 'shared/AngularComponent'
import { BaseModule } from 'shared/base/base.module'
import { AuthService } from 'src/app/services/hubtype-api/auth.service'
import * as auth from '../../actions/auth'
import * as fromRoot from '../../reducers'

@Component({
  selector: 'one-time-login',
  imports: [CommonModule, BaseModule, RouterLink],
  templateUrl: './one-time-login.component.html',
  styleUrls: ['./one-time-login.component.scss'],
})
export class OneTimeLoginComponent extends AngularComponent implements OnInit {
  isAnInvite = false
  expiredToken = false
  token = ''

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject('authService') private authService: AuthService
  ) {
    super()
  }

  ngOnInit(): void {
    this.isAnInvite = this.activatedRoute.snapshot.data.isAnInvite
    this.token = this.activatedRoute.snapshot.queryParams?.token

    if (this.existLoggedSession()) {
      const currentUrl = this.router.url
      this.store.dispatch(new auth.LogoutAction())
      setTimeout(() => {
        window.location.href = currentUrl
      }, 100)
    } else {
      this.loginWithOneTimeToken()
    }
  }

  private existLoggedSession(): boolean {
    const savedAuth = localStorage.getItem('auth')
    const authJson = savedAuth ? JSON.parse(savedAuth) : null
    const savedAccessToken = authJson?.oauth?.access_token
    return !!savedAccessToken
  }

  private loginWithOneTimeToken() {
    this.authService.loginWithOneTimeToken(this.token).subscribe({
      next: oauth => {
        this.store.dispatch(new auth.LoginAction(oauth))
        const url = this.isAnInvite
          ? '/auth/create-password'
          : '/auth/reset-password'
        this.router.navigate([url])
      },
      error: err => {
        this.expiredToken = true
      },
    })
  }
}
