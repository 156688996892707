import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { customEmailValidator, validateAllFormFields } from 'models/form'
import { finalize, first } from 'rxjs'
import { HtButtonDSComponent } from 'shared/base/ht-button-ds/ht-button-ds.component'
import { UserService } from '../../services/hubtype-api/user.service'

@Component({
  selector: 'app-reset-pwd',
  templateUrl: 'reset_pwd.component.html',
  styleUrls: ['reset_pwd.component.scss'],
  standalone: false,
})
export class ResetPwdComponent implements OnInit {
  @ViewChild('submitButton') submitButton: HtButtonDSComponent
  error: string
  form = this.fb.group({
    email: ['', [Validators.required, customEmailValidator()]],
  })
  initialEmail: string
  sended = false
  resended = false

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    @Inject('userService') private userService: UserService
  ) {}

  ngOnInit() {
    this.setInitialEmail()
  }

  onSubmit() {
    validateAllFormFields(this.form)

    if (this.form.invalid) {
      return false
    }

    this.submitButton?.activateLoading()
    this.userService
      .forgotPassword(this.form.value.email)
      .pipe(
        finalize(() => {
          this.submitButton?.deactivateLoading()
        })
      )
      .subscribe(resp => {
        this.sended = true
      })
  }

  resendEmail() {
    this.onSubmit()
    this.resended = true
  }

  private setInitialEmail() {
    this.route.queryParams.pipe(first()).subscribe(params => {
      this.form.patchValue({
        email: params['email'],
      })
    })
  }
}
