import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { customEmailValidator, validateAllFormFields } from 'models/form'
import { IntegrationAuthEvent } from 'models/integration-event'
import { LoginAction } from 'src/app/actions/auth'
import { State } from 'src/app/reducers'
import { IntegrationsService } from 'src/app/services/integrations.service'
import { CustomError } from '../../../constants/errors'
import { AuthService } from '../../../services/hubtype-api/auth.service'

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: false,
})
export class LoginFormComponent implements OnInit {
  error: string
  isSigningIn = false
  form = this.fb.group({
    email: ['', [Validators.required, customEmailValidator()]],
    password: ['', [Validators.required]],
  })

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    @Inject('authService') private authService: AuthService,
    private integrationsService: IntegrationsService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.error = null
    })
  }

  onSubmit() {
    validateAllFormFields(this.form)
    if (this.form.invalid) {
      return
    }
    const { email, password } = this.form.value
    this.isSigningIn = true
    this.authService.login(email, password).subscribe(
      res => {
        this.store.dispatch(new LoginAction(res))

        if (this.integrationsService.isUsingIntegrations()) {
          console.log('Integration: send authenticated event')
          this.integrationsService.sendEvent(new IntegrationAuthEvent(res))
        }
      },
      ({ error }) => {
        this.isSigningIn = false
        this.error =
          error.error === 'invalid_grant'
            ? CustomError.INVALID_LOGIN.message
            : error.error_description || CustomError.LOGIN_ERROR.message
      }
    )
  }
}
