import produce, { Draft } from 'immer'
import {
  InfrastructureAction,
  InfrastructureActionType,
} from '../actions/infrastructure'
import { pusherVerboseLog } from '../services/pusher.service'
import {
  InfrastructureState,
  infrastructureInitialState,
} from './infrastructure.state'

export function reducer(
  state: InfrastructureState,
  action: InfrastructureAction
): InfrastructureState {
  return produce(
    (
      draftState: Draft<InfrastructureState>,
      appraisalAction: InfrastructureAction
    ) => {
      switch (appraisalAction.type) {
        case InfrastructureActionType.UPDATE_PUSHER_SETTINGS:
          pusherVerboseLog(
            `Updating pusher settings to: ${appraisalAction.payload.cluster} (${appraisalAction.payload.key})`
          )
          draftState.pusherApp = appraisalAction.payload
          break

        case InfrastructureActionType.CLEAR_PUSHER_SETTINGS:
          draftState.pusherApp = null
          break

        default:
          return draftState
      }
    },
    infrastructureInitialState
  )(state, action)
}
